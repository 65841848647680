import { BreakpointObserver } from '@angular/cdk/layout';
import { AsyncPipe, DOCUMENT, NgClass } from '@angular/common';
import { AfterViewInit, Component, DestroyRef, inject, OnInit, Renderer2 } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDrawerMode } from '@angular/material/sidenav';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { select, Store } from '@ngrx/store';
import * as Sentry from '@sentry/browser';
import { EMPTY, filter, Observable, switchMap, tap } from 'rxjs';
import { environment as env } from '../environments/environment';
import * as AppSettingsActions from './app-settings/store/app-settings.actions';
import { ActivityDetectionComponent } from './auth/activity-detection/activity-detection.component';
import * as AuthActions from './auth/store/auth.actions';
import { CustomerPreferences } from './customer/customer.model';
import * as CustomerAddressActions from './customer/store/actions/customer-address.actions';
import * as CustomerEmailActions from './customer/store/actions/customer-email.actions';
import * as CustomerFamilyActions from './customer/store/actions/customer-family.actions';
import * as CustomerPhoneActions from './customer/store/actions/customer-phone.actions';
import * as CustomerActions from './customer/store/actions/customer.actions';
import { selectPrimaryEmailUpdated } from './customer/store/selectors/customer-email.selector';
import { selectCustomerPreferences } from './customer/store/selectors/customer.selector';
import { ContentShellComponent } from './layout/content-shell/content-shell.component';
import { FooterComponent } from './layout/footer/footer.component';
import { HeaderComponent } from './layout/header/header.component';
// import { IntrojsService } from './shared/introjs/introjs.service';
import * as fromApp from './store/app.reducer';
import { ConsoleToggleService } from './utilities/console-toggle.service';

declare global {
  interface Window {
    delighted: any;
    gtag?: Function;
  }
}
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    ActivityDetectionComponent,
    HeaderComponent,
    ContentShellComponent,
    RouterOutlet,
    FooterComponent,
    AsyncPipe,
  ],
})
export class AppComponent implements OnInit, AfterViewInit {
  private store = inject<Store<fromApp.AppState>>(Store);
  private document = inject<Document>(DOCUMENT);
  private renderer = inject(Renderer2);
  private authService = inject(AuthService);
  private bpObserver = inject(BreakpointObserver);
  private router = inject(Router);
  // private introService = inject(IntrojsService);
  private consoleToggleService = inject(ConsoleToggleService);

  title = 'MyKingston';
  isDarkMode = false;
  production = env.production;

  loggedIn$: Observable<boolean>;
  sideNavMode: MatDrawerMode;
  sideNavOpen = 'over';
  customerPreferences: CustomerPreferences;
  hideSideNavInitial = false;
  destroyRef = inject(DestroyRef);
  currentWindow = window;

  constructor() {
    this.consoleToggleService.disableConsoleInProduction();
    /** START : Code to Track Page View using gtag.js */
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      window.gtag('event', 'page_view', {
        page_path: event.urlAfterRedirects,
      });
    });
    /** END : Code to Track Page View  using gtag.js */
  }

  ngOnInit() {
    const delighted = window.delighted;
    this.store.dispatch(AppSettingsActions.fetchApplicationSettings());

    this.authService.user$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((user) => {
      if (user) {
        Sentry.setUser({
          id: user['http://mykingston/customerId'],
          username: user.name,
          email: user.email,
        });

        if (env.production) {
          delighted.survey({
            email: user.email,
            name: user.name,
            createdAt: new Date(),
            properties: {
              customerId: user['http://mykingston/customerId'],
            },
          });
        }
      } else {
        if (env.production) {
          delighted.survey({
            name: 'Anonymous user',
          });
        }
      }
    });

    this.loggedIn$ = this.authService.isAuthenticated$;

    //this.authService.error$.subscribe((error) => console.log(error));
    this.loggedIn$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((loggedIn) => {
      this.store.dispatch(AuthActions.checkAuth());
      if (loggedIn) {
        //dispatch most common http requests
        this.store.dispatch(CustomerAddressActions.fetchCustomerAddresses());
        this.store.dispatch(CustomerEmailActions.fetchCustomerEmails());
        this.store.dispatch(CustomerPhoneActions.fetchCustomerPhones());
        this.store.dispatch(CustomerFamilyActions.fetchCustomerFamily());
        this.store.dispatch(AppSettingsActions.wsApiConnect());
      }
    });
    this.store.pipe(select(selectCustomerPreferences), takeUntilDestroyed(this.destroyRef)).subscribe((preferences) => {
      if (preferences) {
        this.customerPreferences = preferences;
        this.toggleDarkMode(preferences.darkMode);
      }
    });
    this.store.pipe(select(selectPrimaryEmailUpdated), takeUntilDestroyed(this.destroyRef)).subscribe((result) => {
      if (result) {
        this.store.dispatch(AuthActions.logout());
      }
    });
  }

  ngAfterViewInit(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntilDestroyed(this.destroyRef),
        switchMap(() => {
          this.hideSideNavInitial = this.router.url.includes('/register');
          if (this.hideSideNavInitial) {
            return EMPTY;
          }
          return this.bpObserver.observe(['(max-width:800px)']).pipe(
            tap((res) => {
              this.sideNavMode = res.matches ? 'over' : 'side';
              this.sideNavOpen = res.matches ? 'close' : 'open';
            })
          );
        })
      )
      .subscribe();

    // combineLatest([
    //   this.store.select(selectCustomerPreferences),
    //   this.store.select(selectCustomerPreferencesLoaded),
    //   this.authService.user$,
    // ]).subscribe(([preferences, loaded, user]) => {
    //   if (user && loaded) {
    //     const profileComplete = user['http://mykingston/profileComplete'];
    //     const shownTutorial = preferences?.shownTutorial || user.email.includes('cypress') ;

    //     if (!shownTutorial && profileComplete) {
    //       if (this.sideNavMode === 'over') {
    //         this.introService.anonymousWalkthroughMobile();
    //       } else {
    //         this.introService.anonymousWalkthrough();
    //       }
    //     }
    //   }
    // });
  }
  toggleDarkMode(isDarkMode: boolean) {
    this.isDarkMode = isDarkMode;
    const hostClass = isDarkMode ? 'theme-dark mat-typography mat-app-background' : 'mat-typography mat-app-background';
    this.renderer.setAttribute(this.document.body, 'class', hostClass);
  }
  switchMode(isDarkMode: boolean) {
    this.toggleDarkMode(isDarkMode);

    //save user preferences
    const newPreferences = { ...this.customerPreferences };
    newPreferences.darkMode = isDarkMode;
    this.store.dispatch(CustomerActions.saveCustomerPreferences({ payload: newPreferences }));
  }

  onMenuToggle() {
    this.sideNavOpen = this.sideNavOpen === 'toggle' ? 'close' : 'toggle';
  }
  onSideNavClosed() {
    this.sideNavOpen = 'close';
  }
}
