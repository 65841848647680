@if (loading) {
<div>
  <div style="width: 50%">
    <ngx-skeleton-loader [theme]="{ 'margin-bottom': '5px' }" />
  </div>
  <div style="width: 40%">
    <ngx-skeleton-loader [theme]="{ 'margin-bottom': '5px' }" />
  </div>
  <div style="width: 60%">
    <ngx-skeleton-loader [theme]="{ 'margin-bottom': '5px' }" />
  </div>
</div>
} @else { @if (districtData?.electoralDistrictName) {
<div class="table">
  <div class="tr">
    <div class="td label">Electoral district:</div>
    <div class="td">{{ districtData?.electoralDistrictName }}</div>
  </div>
  <div class="tr">
    <div class="td label">District #:</div>
    <div class="td">{{ districtData?.electoralDistrictId }}</div>
  </div>
  <div class="tr">
    <div class="td label">Councillor:</div>
    <div data-testid="councillor" class="td">
      <a target="_blank" rel="noopener" [href]="districtData?.councillorWebsiteUrl"
        >{{ districtData?.electoralDistrictCouncillor }}
      </a>
    </div>
  </div>
</div>
} }
