@if ({ loading: loading$ | async, schedule: garbageCollectionSchedule$ | async, error: error$ | async, address:
primaryAddress$ | async, addressError: addressError$ | async, addressLoaded: primaryAddressLoaded$ | async,
widgetSettings: widgetSettings$ | async }; as vm) { @if (vm.widgetSettings.visible) {
<div class="garbage-info" data-testid="waste-collection-widget">
  <h2>Next waste collection</h2>
  @if ((vm.loading || (!vm.addressLoaded && loggedIn)) && (!(vm.addressError || !vm.address?.cokAddressId &&
  !vm.address?.electoralDistrictId) || !showDescription)) {
  <div class="loading">
    <div class="item">
      <ngx-skeleton-loader count="2" [theme]="{ width: '60%', 'margin-bottom': '10px' }" />
    </div>
    <ul style="margin: 0">
      <li>
        <ngx-skeleton-loader count="1" appearance="circle" [theme]="{ height: '24px', width: '24px' }" />
        <ngx-skeleton-loader [theme]="{ width: '30%' }" class="service-label" />
      </li>
      <li>
        <ngx-skeleton-loader count="1" appearance="circle" [theme]="{ height: '24px', width: '24px' }" />
        <ngx-skeleton-loader [theme]="{ width: '40%' }" class="service-label" />
      </li>
      <li>
        <ngx-skeleton-loader count="1" appearance="circle" [theme]="{ height: '24px', width: '24px' }" />
        <ngx-skeleton-loader [theme]="{ width: '30%' }" class="service-label" />
      </li>
    </ul>
    <ngx-skeleton-loader [theme]="{ width: '60%', 'margin-bottom': '0px' }" />
  </div>
  } @else { @if (!vm.error.code && vm.schedule?.events?.length && !(vm.addressError || !vm.address?.cokAddressId &&
  !vm.address?.electoralDistrictId && showDescription)) {
  <div>
    @for (event of vm.schedule?.events; track event) {
    <div class="garbage-events">
      @if (event.services.length > 0) {
      <div>
        <p>
          @if (vm.address?.unit) {
          <span>{{ vm.address.unit }} - </span>
          }{{ vm.address.streetAddress }}
        </p>
        <p data-testid="waste-schedule">{{ event.day }} - {{ event.date | date }}</p>
        <app-garbage-services-list [garbageServices]="event.services" />
      </div>
      }
    </div>
    }
    <div class="action-buttons">
      <div>
        <a
          mat-stroked-button
          [href]="
            'https://www.cityofkingston.ca/resident/garbage-recycling/waste-tools/calendar#rc-place=' +
            vm.schedule.placeId
          "
          target="_blank"
          rel="noopener"
          >Full schedule</a
        >
      </div>
      <a mat-flat-button color="primary" [href]="crmPortalLink" target="_blank" data-testid="missed-garbage">
        Report missed pickup
      </a>
    </div>
  </div>
  } }
  <ng-template #loaded>
    @if (!vm.error.code && vm.schedule?.events?.length) {
    <div>
      @for (event of vm.schedule?.events; track event) {
      <div class="garbage-events">
        @if (event.services.length > 0) {
        <div>
          <p>
            @if (vm.address?.unit) {
            <span>{{ vm.address.unit }} - </span>
            }{{ vm.address.streetAddress }}
          </p>
          <p data-testid="waste-schedule">{{ event.day }} - {{ event.date | date }}</p>
          <app-garbage-services-list [garbageServices]="event.services" />
        </div>
        }
      </div>
      }
      <div class="action-buttons">
        <div>
          <a
            mat-stroked-button
            [href]="
              'https://www.cityofkingston.ca/resident/garbage-recycling/waste-tools/calendar#rc-place=' +
              vm.schedule.placeId
            "
            target="_blank"
            rel="noopener"
            >Full schedule</a
          >
        </div>
        <a mat-flat-button color="primary" [href]="crmPortalLink" target="_blank" data-testid="missed-garbage">
          Report missed pickup
        </a>
      </div>
    </div>
    }
  </ng-template>
  @if (!vm.address && !vm.addressError && vm.addressLoaded && !vm.error.code && !showDescription) {
  <div>
    <p>Add a Kingston address to view details of your next waste collection.</p>
    <app-add-residential-address />
  </div>
  } @if (vm.addressError && !showDescription) {
  <div class="error-message">
    <app-message-display errorMessage="" [showCloseButton]="false">
      Unable to access your address to show your collection schedule.
    </app-message-display>
  </div>
  } @if (vm.error.code === 'API_ERROR' && !(vm.address?.isKingston && vm.address && !vm.address?.cokAddressId &&
  !vm.address?.electoralDistrictId) && !vm.loading) {
  <div>
    <app-message-display [showCloseButton]="false" messageType="error">
      Unable to get your collection schedule.
    </app-message-display>
  </div>
  }@if (!showDescription && vm.address?.isKingston && vm.address && !vm.address?.cokAddressId &&
  !vm.address?.electoralDistrictId && !vm.loading) {
  <div>
    <app-message-display [showCloseButton]="false" messageType="info">
      No collection schedule found for {{ vm.address.streetAddress }}.
    </app-message-display>
    <button
      mat-raised-button
      color="accent"
      data-testid="edit-address"
      (click)="onEdit(vm.address)"
      style="margin: 0.5rem 0 0.5rem 0"
    >
      Edit address
    </button>
  </div>
  } @if (vm.error.code === '204') {
  <div>
    <app-message-display [showCloseButton]="false" messageType="info" [message]="vm.error.message" />
  </div>
  } @if (showDescription && (!loggedIn || (vm.error.code === 'NOT_KINGSTON' && !vm.loading )|| (vm.address?.isKingston
  && vm.address && !vm.address.cokAddressId && !vm.address?.electoralDistrictId) || !vm.address)) {
  <div>Stay informed about upcoming waste collection services.</div>
  } @if (vm.error.code === 'NOT_KINGSTON' && !showDescription && !vm.loading) {
  <div>
    <app-message-display [showCloseButton]="false" messageType="info">
      A Kingston address is required for waste collection information.
    </app-message-display>
    <button
      mat-raised-button
      color="accent"
      data-testid="edit-address"
      (click)="onEdit(vm.address)"
      style="margin: 0.5rem 0 0.5rem 0"
    >
      Edit address
    </button>
  </div>
  }
</div>
} }
